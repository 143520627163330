import React from 'react';
import Colors from "../../useclass/colors"
import TextBig from "../../components/texts/text.big";
import TextSmall from "../../components/texts/text.small";
import CardConnect from "../../components/cards/card.connect";
import CardHome from "../../components/cards/card.home";

function Login() {
    const styles = {
        container: {
            width: "100%",
            minHeight: "77.5vh"
        },
        containerCards: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        itemConnect: {
            flex: 1,
            minWidth: 500,
            marginBottom: 50,
        },
        itemPC: {
            flex: 2.2,
            minWidth: 700,
            marginBottom: 50,
        }
    };

    return (
        <div style={styles.container}>

            <TextBig
                title={"Bienvenue"}
                subtitle={"Dans le Barème Rapide® Digital Langloÿs"}
            />
            <div style={styles.containerCards}>
                <div style={styles.itemConnect}>
                    <CardConnect/>
                </div>

                <div style={styles.itemPC}>
                    <CardHome/>
                </div>

            </div>
            <TextSmall
                text={"Pour plus d’informations, contactez-nous par e-mail sur info@langloys.com ou par téléphone au 04.91.25.13.38"}
            />
        </div>
    );
}

export default Login;
