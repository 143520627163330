import * as React from "react";
import Colors from "../../useclass/colors";
import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_BASE_URL, useForceUpdate} from "../../config";
import {useSnackbar} from 'notistack';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {useDispatch, useSelector} from "react-redux";
import "../../stylesheets/simulateur.main.css"
import LinkPDF from "../../utils/simulateurs/link.PDF";
import roundDecimal from "../../utils/roundDecimal";
import './modal.css';
import close from "../../assets/images/close.png"
import "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import useIsMountedRef from "../../utils/mountedRef";
import Simulateur_A2_16 from "./simulateur.A2.16/simulateur.A2.16";
import Simulateur_A2_17 from "./simulateur.A2.17/simulateur.A2.17";
import Simulateur_A2_18 from "./simulateur.A2.18/simulateur.A2.18";
import Simulateur_A1_11 from "./simulateur.A1.11/simulateur.A1.11";
import Simulateur_A1_8 from "./simulateur.A1.8/simulateur.A1.8";
import Simulateur_A1_12PRO from "./simulateur.A1.12PRO/simulateur.A1.12PRO";
import Simulateur_A2_15 from "./simulateur.A2.15/simulateur.A2.15";
import Simulateur_A1_1 from "./simulateur.A1.1/simulateur.A1.1";
import Simulateur_A1_2 from "./simulateur.A1.2/simulateur.A1.2";
import Simulateur_A1_3 from "./simulateur.A1.3/simulateur.A1.3";
import Simulateur_A1_3bis from "./simulateur.A1.3bis/simulateur.A1.3bis";
import Simulateur_A2_1 from "./simulateur.A2.1/simulateur.A2.1";
import Simulateur_A1_5 from "./simulateur.A1.5/simulateur.A1.5";
import Simulateur_A1_7 from "./simulateur.A1.7/simulateur.A1.7";
import Simulateur_A1_9 from "./simulateur.A1.9/simulateur.A1.9";
import Simulateur_A1_10 from "./simulateur.A1.10/simulateur.A1.10";
import Simulateur_A1_12 from "./simulateur.A1.12/simulateur.A1.12";
import Simulateur_A1_12VEFA from "./simulateur.A1.12VEFA/simulateur.A1.12VEFA";
import Simulateur_A1_14 from "./simulateur.A1.14/simulateur.A1.14";
import Simulateur_A1_15 from "./simulateur.A1.15/simulateur.A1.15";
import Simulateur_A1_16bis from "./simulateur.A1.16bis/simulateur.A1.16bis";
import Simulateur_A1_16 from "./simulateur.A1.16/simulateur.A1.16";
import Simulateur_A1_17 from "./simulateur.A1.17/simulateur.A1.17";
import Simulateur_A1_24 from "./simulateur.A1.24/simulateur.A1.24";
import Simulateur_A1_24bis from "./simulateur.A1.24bis/simulateur.A1.24bis";
import Simulateur_A1_25 from "./simulateur.A1.25/simulateur.A1.25";
import Simulateur_A1_25bis from "./simulateur.A1.25bis/simulateur.A1.25bis";
import Simulateur_A1_26 from "./simulateur.A1.26/simulateur.A1.26";
import Simulateur_A1_27 from "./simulateur.A1.27/simulateur.A1.27";
import Simulateur_A1_28 from "./simulateur.A1.28/simulateur.A1.28";
import Simulateur_A1_29 from "./simulateur.A1.29/simulateur.A1.29";
import Simulateur_A1_30 from "./simulateur.A1.30/simulateur.A1.30";
import Simulateur_A2_14 from "./simulateur.A2.14/simulateur.A2.14";
import Simulateur_A2_19 from "./simulateur.A2.19/simulateur.A2.19";
import Simulateur_A2_20 from "./simulateur.A2.20/simulateur.A2.20";
import Simulateur_ESD from "./simulateur.ESD/simulateur.ESD";
import Simulateur_ESDM from "./simulateur.ESDM/simulateur.ESDM";
import Simulateur_ESI from "./simulateur.ESI/simulateur.ESI";
import Simulateur_ESIM from "./simulateur.ESIM/simulateur.ESIM";
import Simulateur_ASimmo1 from "./simulateur.ASimmo1/simulateur.ASimmo1";
import Simulateur_ASimmo3 from "./simulateur.ASimmo3/simulateur.ASimmo3";
import Simulateur_ASimmo4 from "./simulateur.ASimmo4/simulateur.ASimmo4";
import Simulateur_ASimmo5 from "./simulateur.ASimmo5/simulateur.ASimmo5";
import Simulateur_ASimmo6 from "./simulateur.ASimmo6/simulateur.ASimmo6";
import Simulateur_A1_4 from "./simulateur.A1.4/simulateur.A1.4";
import Simulateur_A2_8 from "./simulateur.A2.8/simulateur.A2.8";
import Simulateur_A2_2 from "./simulateur.A2.2/simulateur.A2.2";
import Simulateur_A2_3 from "./simulateur.A2.3/simulateur.A2.3";
import Simulateur_A2_4 from "./simulateur.A2.4/simulateur.A2.4";
import Simulateur_A2_5 from "./simulateur.A2.5/simulateur.A2.5";
import Simulateur_A2_6 from "./simulateur.A2.6/simulateur.A2.6";
import Simulateur_A2_7 from "./simulateur.A2.7/simulateur.A2.7";
import Simulateur_A1_5bis from "./simulateur.A1.5bis/simulateur.A1.5bis";
import Simulateur_EDD from "./simulateur.EDD/simulateur.EDD";
import Simulateur_MC_EDD from "./simulateur.MC.EDD/simulateur.MC.EDD";
import Simulateur_32 from "./simulateur.32/simulateur.32";
import Simulateur_34 from "./simulateur.34/simulateur.34";
import Simulateur_35 from "./simulateur.34/simulateur.34";
import Simulateur_36 from "./simulateur.36/simulateur.36";
import Simulateur_37 from "./simulateur.37/simulateur.37";
import Simulateur_38 from "./simulateur.38/simulateur.38";
import Simulateur_39 from "./simulateur.39/simulateur.39";
import Simulateur_40 from "./simulateur.40/simulateur.40";
import Simulateur_41 from "./simulateur.41/simulateur.41";
import Simulateur_78 from "./simulateur.78/simulateur.78";
import Simulateur_79 from "./simulateur.79/simulateur.79";
import Simulateur_80 from "./simulateur.80/simulateur.80";
import Simulateur_81 from "./simulateur.81/simulateur.81";
import Simulateur_82 from "./simulateur.82/simulateur.82";


ChartJS.register(ArcElement, Tooltip, Legend);


function SimulateurMain(props) {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    const isMountedRef = useIsMountedRef();
    const isMountedRefEmol = useIsMountedRef();
    const [parentSimulateur, setParentSimulateur] = useState({})
    const [departements, setDepartements] = useState("")
    const [createPDF, setCreatePDF] = useState(false)
    var data = useSelector((state) => state.simulateur);
    const listOfSimuWithDMTG = [48, 49, 50, 51, 52, 57]
    const [calculateDMTGState, setCalculateDMTGState] = useState(false)
    const [readyPDF, setReadyPDF] = useState(false)
    const simuVentes = [1, 2, 3, 4]
    const alsaceMoselle = [57, 67, 68]
    const mutationReduite = [971, 972, 973, 974]
    const hypothequeReduite = [974, 976]
    const majorationEmol125 = [971, 972, 973]
    const majorationEmol14 = [974, 976, 977, 978]
    const multiComponentInputSimu = [34, 35, 81, 82]

    //var selectorDataStored = useSelector((state) => state.simulateur);
    //const [currentMontant, setCurrentMontant] = useState(0)
    //const [arrayContent, setArrayContent] = useState([])
    const [show, setShow] = useState(false)
    const [showEmol, setShowEmol] = useState(false)
    let [currentEmolSimu, setCurrentEmolSimu] = useState([])
    const isMountedRefCurrentSimuEmol = useIsMountedRef();


    const [simulateurResponse, setSimulateurResponse] = useState([])
    const [allEmoluments, setAllEmoluments] = useState([])
    const [baseSize, setBaseSize] = useState(0)
    const [remarque, setRemarque] = useState("")


    const [simulateurObject, setSimulateurObject] = useState(
        {
            "data":
                {
                    "simulateurid": parentSimulateur.id,
                    "departementid": null,
                    "inputs": null,
                    "DMTG": null,
                    "dependances": []
                }
            ,
            "results":
                {
                    "output": [],
                    "custom": []
                }
        }
    )

    useEffect(() => {
        localStorage.setItem("remarque", remarque)
    }, [remarque]);

    useEffect(() => {
        if (readyPDF === true && simulateurObject["results"]["custom"].length > 0) {
            pushAffinage()
        }
    }, [readyPDF]);

    useEffect(() => {
        simulateurObject.data.departementid = data.departementid
        localStorage.removeItem("tempo")
    }, [data]);

    useEffect(() => {
        simulateurObject["data"]["dependances"] = data.dependances
    }, [data]);

    useEffect(() => {
        const items = {...localStorage};
        Object.keys(items).map((key, value) => {
            if (isNumeric(key.toString())) {
                localStorage.removeItem(key)
            }
        })
        localStorage.removeItem("outputAffinage");
        localStorage.removeItem("usageId");
        localStorage.removeItem('nameSimu')
        localStorage.removeItem("persoEmol")
    }, [isMountedRef]);

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    const chartRef = useRef(null);

    var forceUpdate = useForceUpdate();

    const getSimulateur = useCallback(() => {
        axios.defaults.withCredentials = true;
        var config = {
            method: 'get', url: `${API_BASE_URL}/simulateurs/${props.match.params.simulateur}`, headers: {
                'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) setParentSimulateur(response.data)
                } else enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRef])

    // const setParentSimu = useCallback(() => {
    //     var config = {
    //         method: 'get',
    //         url: `${API_BASE_URL}/simulateurs/${props.match.params.simulateur}`,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
    //         },
    //     };
    //     axios(config)
    //         .then((response) => {
    //             if (response.status === 200 && response.data != null) {
    //                 if (isMountedRef.current) {
    //                     setParentSimulateur(response.data)
    //                 }
    //             } else {
    //                 enqueueSnackbar('Une erreur est survenue', {
    //                     variant: 'error'
    //                 });
    //                 //    window.location = "/app"
    //             }
    //         })
    //         .catch((err) => {
    //             enqueueSnackbar('Une erreur est survenue', {
    //                 variant: 'error'
    //             });
    //             //  window.location = "/app"
    //         });
    // }, [isMountedRef])

    const styles = {
        container: {
            width: "100%",
            color: Colors.textBlack,
            minHeight: "75vh",
            backgroundColor: Colors.ultraLightGrey,
            fontFamily: "Regular"

        }, ctnButton: {
            width: "90%", marginLeft: "auto", marginRight: "auto", paddingBottom: 30, paddingTop: 30,

        }, ctnButtonValide: {

            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 30,
            paddingTop: 30,
            textAlign: "center",
            maxWidth: 250

        }, buttonCalculate: {
            display: "block",
            margin: "auto",
            width: "30%",
            minWidth: "230px",
            height: 35,
            borderRadius: 5,
            border: "none",
            backgroundColor: "#49708C",
            color: Colors.white,
            fontWeight: "bold",
            cursor: "pointer",
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            hover: "#6399BF",
        }, ctnTable: {
            width: "97%", marginTop: 10, marginBottom: 30, backgroundColor: Colors.white,
            marginLeft: "auto", marginRight: "auto", borderRadius: 4, minWidth: 400,

        }, ctnTableData: {
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            height: "100%",
            overflowY: "scroll",

        }, ctnTableDataElemLeft: {
            width: "100%",
            border: "grey solid 1px",
            borderCollapse: "collapse",
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusBottomRight: 3,
            height: 35,
            marginBottom: 5

        }, ctnTableDataElemRight: {
            width: "100%",
            borderRight: "grey solid 1px",
            borderTop: "grey solid 1px",
            borderBottom: "grey solid 1px",
            borderCollapse: "collapse",
            borderRadius: 3,
            borderRadiusBottomLeft: 0,
            borderRadiusTopLeft: 0,
            height: 35,

        }, ctnTableDataTxt: {
            lineHeight: 0.5, marginLeft: 10, fontSize: 16
        }, table: {
            width: "40%",
            borderCollapse: "collapse",
            marginRight: 5,
            border: "1px solid rgb(238, 236, 236)",
            margin: "auto"
        }, tr: {
            borderCollapse: "collapse", borderRadius: 5, fontWeight: "bold", textAlign: "center"
        }, td: {
            borderCollapse: "collapse", borderRadius: 5, paddingLeft: 10, height: 40,
            fontWeight: "bold", textAlign: "left", borderRight: "1px solid rgb(212, 212, 212)"
        }, td2: {
            borderCollapse: "collapse",
            borderRadius: 5,
            paddingRight: 10,
            height: 40,
            fontWeight: "bold",
            textAlign: "right",
            minWidth: 85,
        }, th: {
            borderCollapse: "collapse",
            borderRadius: 5,
            paddingLeft: 5,
            height: 45,
            textAlign: "center",
            fontStyle: "italic"
        },

        table2: {
            width: "100%", borderCollapse: "collapse", marginRight: 5, border: "1px solid rgb(238, 236, 236)"
        }, thead: {
            backgroundColor: "rgb(216, 211, 201)"
        }, td1form: {
            backgroundColor: "white",
            height: 45,
        }, td1formTitle: {
            backgroundColor: "white",
            height: 45,
            textAlign: "left",
            paddingLeft: 7,
        }, td1formArt: {
            backgroundColor: "white",
            height: 45,
            fontSize: 14,
            fontWeight: "lighter"
        }, td2form: {
            backgroundColor: "rgb(245, 242, 240)",
            height: 45,
        }, td2formTitle: {
            backgroundColor: "rgb(245, 242, 240)",
            height: 45,
            textAlign: "left",
            paddingLeft: 7,
        }, td2formArt: {
            backgroundColor: "rgb(245, 242, 240)",
            height: 45,
            fontSize: 14,
            fontWeight: "lighter"
        }
    };


    useEffect(() => {
        getSimulateur();
    }, [getSimulateur]);


    const getEmoluments = useCallback(() => {
        var config = {
            method: 'get', url: `${API_BASE_URL}/emol_form`, headers: {
                'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRefEmol.current) {
                        response.data.map(e => {
                            e.nombre = 0
                            e.montant2 = 0
                        })
                        setAllEmoluments(response.data)
                        localStorage.setItem("allEmol", JSON.stringify(response.data))
                        getEmolumentsCurrentSimu();
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRefEmol])


    const getEmolumentsCurrentSimu = useCallback(() => {
        var config = {
            method: 'get', url: `${API_BASE_URL}/simul_form/${props.match.params.simulateur}`, headers: {
                'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRefCurrentSimuEmol.current) {
                        var contentEmol = JSON.parse(localStorage.getItem("allEmol"))

                        response.data.map(e => {
                            e.nombre = 0
                            e.montant2 = 0
                            contentEmol.map((value, index) => {
                                if (value.id == e.id) {
                                    contentEmol.splice(index, 1)
                                }
                            })
                        })
                        localStorage.setItem("contentEmol", JSON.stringify(contentEmol))
                        localStorage.setItem("currentEmol", JSON.stringify(response.data))
                        setBaseSize(response.data.length)
                        setCurrentEmolSimu(response.data)
                        localStorage.removeItem("allEmol")
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRefCurrentSimuEmol])

    useEffect(() => {
        getEmoluments();
    }, [getEmoluments]);

    useEffect(() => {
        localStorage.setItem('nameSimu', parentSimulateur.name)
    }, [parentSimulateur]);

    let handleData;

    function dependanceTreatment(dependance, i) {
        return new Promise(resolve => {
            try {
                var config = {
                    method: 'post', url: `${API_BASE_URL}/calculateur`, headers: {
                        'Content-Type': 'application/json',
                        'Authorization': JSON.parse(localStorage.getItem("user")).jwt
                    }, data: dependance
                };
                axios(config)
                    .then((response) => {
                        if (response.status === 200 && response.data != null) {

                            handleData.dependances[i] = {
                                "output": response.data,
                                "inputs": dependance.inputs,
                                "simulateurid": dependance.simulateurid,
                                "departementid": dependance.departementid,
                            }
                            simulateurObject["data"]["dependances"][i] = handleData.dependances[i]
                            resolve();

                        } else {
                            enqueueSnackbar('Le simulateur ' + parentSimulateur.name + " est mal renseigné", {
                                variant: 'error'
                            });
                            Promise.reject("fail dependence")
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar('Tous les champs n\'ont pas été correctement renseignés.', {
                            variant: 'error'
                        });
                        Promise.reject("fail dependence")
                    });
            } catch (e) {
                return null
            }
        })
    }


    async function check(data) {
        localStorage.setItem("content", JSON.stringify(data));
        handleData = JSON.parse(localStorage.getItem("content"));

        if (data?.dependances?.length > 0 || data?.dependances !== undefined) {
            if (multiComponentInputSimu.includes(data.simulateurid)) {
                for (let i = 0; i < handleData.dependances.length; i++) {
                    simulateurObject["data"]["dependances"][i] = handleData.dependances[i]
                }
            } else {
                for (let i = 0; i < handleData.dependances.length; i++) {
                    let handle = handleData.dependances[i]
                    simulateurObject["data"]["dependances"][i] = handleData.dependances[i]
                    await dependanceTreatment(handle, i)
                }
            }
            return simulateurObject["data"]
        } else return data
    }


    const getDepart = useCallback(() => {
        var config = {
            method: 'get', url: `${API_BASE_URL}/departements`, headers: {
                'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (isMountedRef.current) {
                        setDepartements(response.data)
                    }
                } else {
                    enqueueSnackbar('Une erreur est survenue', {
                        variant: 'error'
                    });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Une erreur est survenue', {
                    variant: 'error'
                });
            });
    }, [isMountedRef])


    useEffect(() => {
        getDepart();
    }, [getDepart]);


    function calculate() {
        simulateurObject["data"]["inputs"] = data.inputs
        simulateurObject["data"]["departementid"] = data.departementid
        simulateurObject["data"]["simulateurid"] = data.simulateurid
        simulateurObject["data"]["DMTG"] = data.DMTG


        if (simulateurObject["data"]["departementid"] === undefined || isNaN(simulateurObject["data"]["departementid"]) || simulateurObject["data"]["departementid"] == 0) {
            enqueueSnackbar('Vous devez renseigner tous les champs afin de procéder au calcul', {
                variant: 'error'
            });
            return;
        }
        check(simulateurObject["data"]).then(r => {
            localStorage.setItem("requestSimu", JSON.stringify(r))

            //simulateurObject["data"]["inputs"] = r


            var config = {
                method: 'post', url: `${API_BASE_URL}/calculateur`, headers: {
                    'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
                }, data: r
            };
            axios(config)
                .then((response) => {
                    if (response.status === 200 && response.data != null) {
                        localStorage.setItem("usageId", response.data.usage_id)
                        response.data.saveEmol = response.data.Emol_total;

                        setSimulateurResponse(response.data)

                        //   simulateurObject["results"]["output"] = (response.data)

                        var allData = []
                        if (response.data.Provision_total !== undefined) {
                            allData.push({
                                key: "Provision_total",
                                name: "Provision",
                                value: roundDecimal(parseFloat(response.data.Provision_total), 2).toString() + " €",
                            })
                        }
                        if (response.data.Provision_Pourcentage_total !== undefined) {
                            allData.push({
                                key: "Provision_Pourcentage_total",
                                name: "Pourcentage de la provision",
                                value: roundDecimal(parseFloat(response.data.Provision_Pourcentage_total), 2).toString() + " %",
                            })
                        }
                        if (response.data.Emol_total !== undefined) {
                            allData.push({
                                key: "Emol_total",
                                name: "Emoluments HT",
                                value: roundDecimal(parseFloat(response.data.Emol_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_formalites_total !== undefined) {
                            allData.push({
                                key: "Emol_Form_Total",
                                name: "Dont total des émoluments de formalités",
                                value: roundDecimal(parseFloat(response.data.emoluments_formalites_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_formalites !== undefined) {
                            allData.push({
                                key: "Emol_Form",
                                name: "Dont émoluments de formalités",
                                value: roundDecimal(parseFloat(response.data.emoluments_formalites), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_majoration_total !== undefined) {
                            if (response.data.emoluments_majoration_total !== 0) {
                                allData.push({
                                    key: "Major",
                                    name: "Dont majoration tarif DOM",
                                    value: roundDecimal(parseFloat(response.data.emoluments_majoration_total), 2).toString() + " €"
                                })
                            }
                        }
                        if (response.data.TVA_total !== undefined) {
                            allData.push({
                                key: "TVA_total",
                                name: "TVA sur émoluments",
                                value: roundDecimal(parseFloat(response.data.TVA_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.Tresor_total !== undefined) {
                            allData.push({
                                key: "Tresor_total",
                                name: "Trésor",
                                value: roundDecimal(parseFloat(response.data.Tresor_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.DMTG !== undefined) {
                            allData.push({
                                key: "DMTG",
                                name: "Dont DMTG",
                                value: roundDecimal(parseFloat(response.data.DMTG), 2).toString() + " €"
                            })
                        }
                        if (response.data.Debours_total !== undefined) {
                            allData.push({
                                key: "Debours_total",
                                name: "Débours",
                                value: roundDecimal(parseFloat(response.data.Debours_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.Simu !== undefined) {
                            allData.push({
                                key: "Simu",
                                name: "Simulateur",
                                value: roundDecimal(parseFloat(response.data.Simu), 2).toString()
                            })
                        }
                        if (response.data.taux_tva !== undefined) {
                            allData.push({
                                key: "taux_tva",
                                name: "Taux TVA",
                                value: roundDecimal((parseFloat(response.data.taux_tva) * 100), 2).toString() + " %"
                            })
                        }
                        if (response.data.emoluments_actes_total !== undefined) {
                            allData.push({
                                key: "emoluments_actes_total",
                                name: "Emoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_actes !== undefined) {
                            allData.push({
                                key: "emoluments_actes",
                                name: "Emoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes), 2).toString() + " €"
                            })
                        }
                        if (response.data.emol_promesse !== undefined) {
                            allData.push({
                                key: "emol_promesse",
                                name: "Emoluments promesse",
                                value: roundDecimal(parseFloat(response.data.emol_promesse), 2).toString() + " €"
                            })
                        }
                        if (response.data.debours_urbanisme !== undefined) {
                            allData.push({
                                key: "debours_urbanisme",
                                name: "Autres debours et/ou urbanisme",
                                value: roundDecimal(parseFloat(response.data.debours_urbanisme), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_tresor !== undefined) {
                            allData.push({
                                key: "base_tresor",
                                name: "Base de calcul du trésor",
                                value: roundDecimal(parseFloat(response.data.base_tresor), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication !== undefined) {
                            allData.push({
                                key: "csi_publication",
                                name: "CSI de publication",
                                value: roundDecimal(parseFloat(response.data.csi_publication), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_mutation !== undefined) {
                            allData.push({
                                key: "droit_mutation",
                                name: "Droits de mutation",
                                value: roundDecimal(parseFloat(response.data.droit_mutation), 2).toString() + " €"
                            })
                        }
                        if (response.data.zrr_mutation !== undefined) {
                            allData.push({
                                key: "zrr_mutation",
                                name: "Droits de mutation ZRR",
                                value: roundDecimal(parseFloat(response.data.zrr_mutation), 2).toString() + " €"
                            })
                        }
                        if (response.data.taxe_ile_de_france !== undefined) {
                            allData.push({
                                key: "taxe_ile_de_france",
                                name: "Taxe additionnelle Île-de-France",
                                value: roundDecimal(parseFloat(response.data.taxe_ile_de_france), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_etat_promesse !== undefined) {
                            allData.push({
                                key: "droit_etat_promesse",
                                name: "Droit état promesse",
                                value: roundDecimal(parseFloat(response.data.droit_etat_promesse), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_etat !== undefined) {
                            allData.push({
                                key: "droit_etat",
                                name: "Droit état",
                                value: roundDecimal(parseFloat(response.data.droit_etat), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_etat_acte_separe !== undefined) {
                            allData.push({
                                key: "droit_etat_acte_separe",
                                name: "Droit état acte séparé",
                                value: roundDecimal(parseFloat(response.data.droit_etat_acte_separe), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_inscription !== undefined) {
                            allData.push({
                                key: "csi_inscription",
                                name: "CSI d'inscription",
                                value: roundDecimal(parseFloat(response.data.csi_inscription), 2).toString() + " €"
                            })
                        }
                        if (response.data.tpf !== undefined) {
                            allData.push({
                                key: "tpf",
                                name: "Taxe de publicité foncière",
                                value: roundDecimal(parseFloat(response.data.tpf), 2).toString() + " €"
                            })
                        }
                        if (response.data.debours_pouvoir !== undefined) {
                            allData.push({
                                key: "debours_pouvoir",
                                name: "Débours pouvoir",
                                value: roundDecimal(parseFloat(response.data.debours_pouvoir), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_etat_simplifie !== undefined) {
                            allData.push({
                                key: "droit_etat_simplifie",
                                name: "Droit état simplifié",
                                value: roundDecimal(parseFloat(response.data.droit_etat_simplifie), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_partage !== undefined) {
                            allData.push({
                                key: "droit_partage",
                                name: "Droit partage",
                                value: roundDecimal(parseFloat(response.data.droit_partage), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_emol !== undefined) {
                            allData.push({
                                key: "base_emol",
                                name: "Base de calcul des émoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.base_emol), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_emol_1 !== undefined) {
                            allData.push({
                                key: "base_emol_1",
                                name: "Base de calcul des émoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.base_emol_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_emol_2 !== undefined) {
                            allData.push({
                                key: "base_emol_2",
                                name: "Base de calcul des émoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.base_emol_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_emol_3 !== undefined) {
                            allData.push({
                                key: "base_emol_3",
                                name: "Base de calcul des émoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.base_emol_3), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_actes_1 !== undefined) {
                            allData.push({
                                key: "emoluments_actes_1",
                                name: "Emoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_actes_2 !== undefined) {
                            allData.push({
                                key: "emoluments_actes_2",
                                name: "Emoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_actes_3 !== undefined) {
                            allData.push({
                                key: "emoluments_actes_3",
                                name: "Emoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes_3), 2).toString() + " €"
                            })
                        }
                        if (response.data.rapport_total !== undefined) {
                            allData.push({
                                key: "rapport_total",
                                name: "Rapport total",
                                value: roundDecimal(parseFloat(response.data.rapport_total), 2).toString() + " €"
                            })
                        }
                        if (response.data.rapport_especes !== undefined) {
                            allData.push({
                                key: "rapport_especes",
                                name: "Rapport espèces",
                                value: roundDecimal(parseFloat(response.data.rapport_especes), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_partage_rapport !== undefined) {
                            allData.push({
                                key: "droit_partage_rapport",
                                name: "Droit partage rapport",
                                value: roundDecimal(parseFloat(response.data.droit_partage_rapport), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_rapport !== undefined) {
                            allData.push({
                                key: "csi_publication_rapport",
                                name: "CSI de publication rapport",
                                value: roundDecimal(parseFloat(response.data.csi_publication_rapport), 2).toString() + " €"
                            })
                        }
                        if (response.data.taux_usufruit_1 !== undefined) {
                            allData.push({
                                key: "taux_usufruit_1",
                                name: "Taux usufruit donateur 1",
                                value: roundDecimal(parseFloat(response.data.taux_usufruit_1), 2).toString() + " %"
                            })
                        }
                        if (response.data.taux_usufruit_2 !== undefined) {
                            allData.push({
                                key: "taux_usufruit_2",
                                name: "Taux usufruit donateur 2",
                                value: roundDecimal(parseFloat(response.data.taux_usufruit_2), 2).toString() + " %"
                            })
                        }
                        if (response.data.taux_nue_propriete_1 !== undefined) {
                            allData.push({
                                key: "taux_nue_propriete_1",
                                name: "Taux nue-propriété donateur 1",
                                value: roundDecimal(parseFloat(response.data.taux_nue_propriete_1), 2).toString() + " %"
                            })
                        }
                        if (response.data.taux_nue_propriete_2 !== undefined) {
                            allData.push({
                                key: "taux_nue_propriete_2",
                                name: "Taux nue-propriété donateur 2",
                                value: roundDecimal(parseFloat(response.data.taux_nue_propriete_2), 2).toString() + " %"
                            })
                        }
                        if (response.data.valeur_bien_1 !== undefined) {
                            allData.push({
                                key: "valeur_bien_1",
                                name: "Valeur du bien donateur 1",
                                value: roundDecimal(parseFloat(response.data.valeur_bien_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.valeur_bien_2 !== undefined) {
                            allData.push({
                                key: "valeur_bien_2",
                                name: "Valeur du bien donateur 2",
                                value: roundDecimal(parseFloat(response.data.valeur_bien_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.Usufruit_1 !== undefined) {
                            allData.push({
                                key: "Usufruit_1",
                                name: "Usufruit donateur 1",
                                value: roundDecimal(parseFloat(response.data.Usufruit_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.Usufruit_2 !== undefined) {
                            allData.push({
                                key: "Usufruit_2",
                                name: "Usufruit donateur 2",
                                value: roundDecimal(parseFloat(response.data.Usufruit_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.Nue_propriete_1 !== undefined) {
                            allData.push({
                                key: "Nue_propriete_1",
                                name: "Nue-propriété donateur 1",
                                value: roundDecimal(parseFloat(response.data.Nue_propriete_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.Nue_propriete_2 !== undefined) {
                            allData.push({
                                key: "Nue_propriete_2",
                                name: "Nue-propriété donateur 2",
                                value: roundDecimal(parseFloat(response.data.Nue_propriete_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_tresor_1 !== undefined) {
                            allData.push({
                                key: "base_tresor_1",
                                name: "Valeur de la nue-propriété donnée donateur 1",
                                value: roundDecimal(parseFloat(response.data.base_tresor_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.base_tresor_2 !== undefined) {
                            allData.push({
                                key: "base_tresor_2",
                                name: "Valeur de la nue-propriété donnée donateur 2",
                                value: roundDecimal(parseFloat(response.data.base_tresor_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_donation_1 !== undefined) {
                            allData.push({
                                key: "droit_donation_1",
                                name: "Droits donation donateur 1",
                                value: roundDecimal(parseFloat(response.data.droit_donation_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_donation_2 !== undefined) {
                            allData.push({
                                key: "droit_donation_2",
                                name: "Droits donation donateur 2",
                                value: roundDecimal(parseFloat(response.data.droit_donation_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_1 !== undefined) {
                            allData.push({
                                key: "csi_publication_1",
                                name: "CSI publication donateur 1",
                                value: roundDecimal(parseFloat(response.data.csi_publication_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_2 !== undefined) {
                            allData.push({
                                key: "csi_publication_2",
                                name: "CSI publication donateur 2",
                                value: roundDecimal(parseFloat(response.data.csi_publication_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.usufruit_retenu !== undefined) {
                            allData.push({
                                key: "usufruit_retenu",
                                name: "Usufruit retenu",
                                value: roundDecimal(parseFloat(response.data.usufruit_retenu), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_usufruit !== undefined) {
                            allData.push({
                                key: "csi_publication_usufruit",
                                name: "CSI de publication usufruit",
                                value: roundDecimal(parseFloat(response.data.csi_publication_usufruit), 2).toString() + " €"
                            })
                        }
                        if (response.data.spf !== undefined) {
                            allData.push({
                                key: "spf",
                                name: "Service de publicité foncière",
                                value: roundDecimal(parseFloat(response.data.spf), 2).toString() + " €"
                            })
                        }
                        if (response.data.comedec_1 !== undefined) {
                            allData.push({
                                key: "comedec_1",
                                name: "Total des comedec à 0.56 €",
                                value: roundDecimal(parseFloat(response.data.comedec_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.comedec_2 !== undefined) {
                            allData.push({
                                key: "comedec_2",
                                name: "Total des comedec à 3.56 €",
                                value: roundDecimal(parseFloat(response.data.comedec_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.debours_adsn !== undefined) {
                            allData.push({
                                key: "debours_adsn",
                                name: "Debours ADSN",
                                value: roundDecimal(parseFloat(response.data.debours_adsn), 2).toString() + " €"
                            })
                        }
                        if (response.data.taxe_hypothecaire !== undefined) {
                            allData.push({
                                key: "taxe_hypothecaire",
                                name: "Taxe hypothécaire",
                                value: roundDecimal(parseFloat(response.data.taxe_hypothecaire), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_requisition_publication !== undefined) {
                            allData.push({
                                key: "droit_requisition_publication",
                                name: "Droit réquisition publication",
                                value: roundDecimal(parseFloat(response.data.droit_requisition_publication), 2).toString() + " €"
                            })
                        }
                        if (response.data.reglement_etablissement !== undefined) {
                            allData.push({
                                key: "reglement_etablissement",
                                name: "Règlement établissement",
                                value: roundDecimal(parseFloat(response.data.reglement_etablissement), 2).toString() + " €"
                            })
                        }
                        if (response.data.reglement_etablissement_lot !== undefined) {
                            allData.push({
                                key: "reglement_etablissement_lot",
                                name: "Règlement établissement lot",
                                value: roundDecimal(parseFloat(response.data.reglement_etablissement_lot), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_lot_1 !== undefined) {
                            allData.push({
                                key: "csi_publication_lot_1",
                                name: "CSI publication lot 1",
                                value: roundDecimal(parseFloat(response.data.csi_publication_lot_1), 2).toString() + " €"
                            })
                        }
                        if (response.data.csi_publication_lot_2 !== undefined) {
                            allData.push({
                                key: "csi_publication_lot_2",
                                name: "CSI publication lot 2",
                                value: roundDecimal(parseFloat(response.data.csi_publication_lot_2), 2).toString() + " €"
                            })
                        }
                        if (response.data.droit_echange !== undefined) {
                            allData.push({
                                key: "droit_echange",
                                name: "Droit échange",
                                value: roundDecimal(parseFloat(response.data.droit_echange), 2).toString() + " €"
                            })
                        }
                        if (response.data.Honoraires !== undefined) {
                            allData.push({
                                key: "Honoraires",
                                name: "Honoraires",
                                value: roundDecimal(parseFloat(response.data.Honoraires), 2).toString() + " €"
                            })
                        }
                        if (response.data.tva_brevet !== undefined) {
                            allData.push({
                                key: "tva_brevet",
                                name: "TVA brevet",
                                value: roundDecimal(parseFloat(response.data.tva_brevet), 2).toString() + " €"
                            })
                        }
                        if (response.data.emoluments_actes_total !== undefined) {
                            allData.push({
                                key: "emoluments_actes_total",
                                name: "Total des émoluments d'actes",
                                value: roundDecimal(parseFloat(response.data.emoluments_actes_total), 2).toString() + " €"
                            })
                        }

                        simulateurObject["results"]["output"] = allData
                        //setArrayContent(allData)
                        setCreatePDF(true)
                        //const base64Image = chartRef?.current?.chartInstance?.toBase64Image();

                        showModal()
                    } else {
                        enqueueSnackbar('Une erreur est survenue', {
                            variant: 'error'
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Tous les champs n\'ont pas été correctement renseignés.', {
                        variant: 'error'
                    });
                });
        })
    }

    function isNumeric(str) {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }

    function pushAffinage() {
        const items = {...localStorage};
        let obj = {}

        let outputAffinage = JSON.parse(localStorage.getItem("outputAffinage"));
        for (let key in items) {
            if (isNumeric(key.toString())) {
                if (items[key] > 0) obj[key] = items[key]
            }
        }

        let dataAffinage = {
            inputs_formalites: obj, outputs_formalites: outputAffinage
        }
        let usageId = localStorage.getItem("usageId")
        var config = {
            method: 'post', url: `${API_BASE_URL}/affinage/${usageId}`, headers: {
                'Content-Type': 'application/json', 'Authorization': JSON.parse(localStorage.getItem("user")).jwt,
            }, data: dataAffinage
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    //localStorage.removeItem("outputAffinage");
                    //localStorage.removeItem("usageId");
                }
            })
            .catch((err) => {
            });
    }

    function showModal() {
        setShow(true)
    }

    function hideModal() {
        setShow(false)
        setReadyPDF(false)
        setShowEmol(false)
        setCalculateDMTGState(false)
    }


    function changeEmol(value, index) {

        localStorage.setItem(parseInt(currentEmolSimu[index.toString()].id), value);
        var simula = currentEmolSimu

        if (value == "") value = 0
        simula[index].nombre = parseInt(value)
        simula[index].montant2 = simula[index].nombre * simula[index].montant


        if (simulateurObject["results"]["custom"].length > 0) {
            //arr = [...simulateurObject["results"]["custom"]]
            localStorage.setItem("tempo", JSON.stringify(simulateurObject["results"]["custom"]))
        } else {
            //arr = [...simulateurObject["results"]["output"]]
            localStorage.setItem("tempo", JSON.stringify(simulateurObject["results"]["output"]))
        }

        var arr = JSON.parse(localStorage.getItem("tempo"))
        arr.map((key, index) => {
                if (key["key"] == "Emol_Form_Total") {
                    var tt = 0
                    simula.map(e => {
                        tt += e.montant2
                    })
                    key.value = roundDecimal(tt, 3).toString() + " €";
                    // calcul a verif
                    arr.map((key) => {
                            if (key["key"] == "Emol_total") {
                                var emol = parseFloat(simulateurResponse["Emol_total"] - parseFloat(simulateurResponse["emoluments_formalites_total"]) + (tt))
                                if (majorationEmol125.includes(data.departementid)) {
                                    emol -= parseFloat(simulateurResponse["emoluments_majoration_total"])
                                    emol *= 1.25
                                    arr.map((key) => {
                                            if (key["key"] == "Major") {
                                                var major = (emol / 1.25) * 0.25
                                                key["value"] = roundDecimal(major, 2).toString() + " €";
                                            }
                                        }
                                    )
                                } else if (majorationEmol14.includes(data.departementid)) {
                                    emol -= parseFloat(simulateurResponse["emoluments_majoration_total"])
                                    emol *= 1.4
                                    arr.map((key) => {
                                            if (key["key"] == "Major") {
                                                var major = (emol / 1.4) * 0.4
                                                key["value"] = roundDecimal(major, 2).toString() + " €";
                                            }
                                        }
                                    )
                                }
                                key["value"] = roundDecimal(emol, 2).toString() + " €";

                                arr.map((key) => {
                                        if (key["key"] == "TVA_total") {
                                            var tva = emol * parseFloat(simulateurResponse["taux_tva"])
                                            key["value"] = roundDecimal(tva, 2).toString() + " €";
                                            var tresor = parseFloat(simulateurResponse["Tresor_total"])
                                            if (parseInt(data.inputs.acte_en_main) === 1) {
                                                arr.map((key) => {
                                                    if (key["key"] == "Tresor_total") {
                                                        var baseTresor = data.inputs.montant_vente - emol - tva - parseFloat(simulateurResponse["Debours"])
                                                        if (simuVentes.includes(data.simulateurid)) {
                                                            baseTresor -= data.inputs.montant_meuble
                                                            if (alsaceMoselle.includes(data.departementid)) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0580669))
                                                            } else if (data.inputs?.zrr === 1) {
                                                                if (data.departementid === 51) {
                                                                    if (baseTresor < 7600) {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                    } else {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 350.1
                                                                    }
                                                                } else if (data.departementid === 71) {
                                                                    if (baseTresor < 30400) {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                    } else {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 1400.42
                                                                    }
                                                                } else {
                                                                    if (baseTresor < 46000) {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                    } else if (data.departementid === 14) {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 2119.06
                                                                    } else {
                                                                        tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 1789.43
                                                                    }
                                                                }
                                                            } else if (data.inputs.taxe_ile_de_france === 1) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0580669 + 0.001 + 0.006))
                                                            } else if (data.departementid === 976) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.004))
                                                            } else if (data.departementid === 977) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0492 + 0.001))
                                                            } else if (data.departementid === 978) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.08 + 0.001))
                                                            } else if (mutationReduite.includes(data.departementid)) {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0580665 + 0.001))
                                                            } else {
                                                                tresor = baseTresor - (baseTresor / (1 + 0.0580669 + 0.001))
                                                            }
                                                        } else {
                                                            var tpf = 0;
                                                            var csiInscription = 0
                                                            var csiPublication = 0
                                                            if (data.departementid !== 977) {
                                                                if (data.departementid === 978) {
                                                                    tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.01
                                                                } else if (data.departementid === 973) {
                                                                    tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0035749
                                                                } else if (hypothequeReduite.includes(data.departementid)) {
                                                                    tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0071422
                                                                } else {
                                                                    tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0071498
                                                                }
                                                            }
                                                            if (!alsaceMoselle.includes(data.departementid)) {
                                                                if (data.departementid === 976) {
                                                                    csiInscription = baseTresor * 0.004
                                                                    if (csiInscription < 8) {
                                                                        csiInscription = 8
                                                                    }
                                                                    csiPublication = baseTresor * 0.004
                                                                    if (csiPublication < 15) {
                                                                        csiPublication = 15
                                                                    }
                                                                } else {
                                                                    csiInscription = baseTresor * 0.0005
                                                                    if (csiInscription < 8) {
                                                                        csiInscription = 8
                                                                    }
                                                                    csiPublication = baseTresor * 0.001
                                                                    if (csiPublication < 15) {
                                                                        csiPublication = 15
                                                                    }
                                                                }
                                                            }
                                                            tresor = tpf + csiInscription + csiPublication
                                                        }
                                                        key["value"] = roundDecimal(parseFloat(simulateurResponse["Tresor_total"]) - parseFloat(simulateurResponse["Tresor"]) + tresor, 2).toString() + " €";
                                                        arr.map((key) => {
                                                                if (key["key"] == "Tresor") {
                                                                    key["value"] = roundDecimal(tresor, 2).toString() + " €";
                                                                }
                                                            }
                                                        )
                                                    }
                                                })
                                            }
                                            arr.map((key) => {
                                                    if (key["key"] == "Provision_total") {
                                                        var provision = parseFloat(simulateurResponse["Provision_total"]) - parseFloat(simulateurResponse["Tresor_total"]) + tresor - parseFloat(simulateurResponse["emoluments_formalites_total"]) + parseFloat(tt) - parseFloat(simulateurResponse["TVA_total"]) + tva
                                                        key["value"] = roundDecimal(provision, 2).toString() + " €";
                                                        arr.map((key) => {
                                                            if (key["key"] == "Provision_Pourcentage_total") {
                                                                if (data.simulateurid === 26) {
                                                                    key.value = roundDecimal(provision / (data.inputs.montant_loyer_annuel * data.inputs.duree_bail) * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_vente) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_vente * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_cautionnement) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_cautionnement * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_acquisition) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_acquisition * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_retrocession) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_retrocession * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_loyer_mensuel) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_loyer_mensuel * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_pret) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_pret * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_creance) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_creance * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_quittance) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_quittance * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_prorogation) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_prorogation * 100, 2).toString() + " %";
                                                                } else if (data.inputs.masse_brute) {
                                                                    key.value = roundDecimal(provision / data.inputs.masse_brute * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_licitee) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_licitee * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_immeuble) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_immeuble * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_loyer_annuel) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_loyer_annuel * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_loyer) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_loyer * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_loyer_versements) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_loyer_versements * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_loyer) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_loyer * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_donateur_1) {
                                                                    key.value = roundDecimal(provision / (data.inputs.montant_donateur_1 + data.inputs.montant_donateur_2) * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_succession) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_succession * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_nantissement) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_nantissement * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_lot_1) {
                                                                    key.value = roundDecimal(provision / (data.inputs.montant_lot_1 + data.inputs.montant_lot_2) * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_lot) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_lot * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_cession) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_cession * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_investissement) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_investissement * 100, 2).toString() + " %";
                                                                } else if (data.inputs.valeur_biens_declares) {
                                                                    key.value = roundDecimal(provision / data.inputs.valeur_biens_declares * 100, 2).toString() + " %";
                                                                } else if (data.inputs.valeur_residuelle) {
                                                                    key.value = roundDecimal(provision / data.inputs.valeur_residuelle * 100, 2).toString() + " %";
                                                                } else if (data.simulateurid === 80) {
                                                                    key.value = roundDecimal(provision / parseFloat(simulateurResponse["valeur_totale"]) * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_partage) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_partage * 100, 2).toString() + " %";
                                                                } else if (data.inputs.montant_partage_brut) {
                                                                    key.value = roundDecimal(provision / data.inputs.montant_partage_brut * 100, 2).toString() + " %";
                                                                } else {
                                                                    key.value = roundDecimal(provision / parseFloat(simulateurResponse["base_emol"]), 2).toString() + " %";
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            )
                                        }
                                    }
                                )
                            }
                        }
                    )
                }
            }
        )
        localStorage.setItem("outputAffinage", JSON.stringify(arr));
        setCurrentEmolSimu(simula)
        localStorage.setItem("persoEmol", JSON.stringify(simula));
        //setArrayContent(arr)
        simulateurObject["results"]["custom"] = arr
        localStorage.removeItem("tempo")
        forceUpdate()
    }


    function showAllEmolument() { // TODO REGLER TEMPORALITE
        if (baseSize !== currentEmolSimu.length) {
            var simulFormEmol = JSON.parse(localStorage.getItem("currentEmol"))
            var arr = simulateurObject["results"]["output"]
            arr.map((key) => {
                    if (key["key"] === "Emol_Form_Total") {
                        var tt = 0
                        simulFormEmol.map((e, index) => {
                            simulFormEmol[index] = currentEmolSimu[index]
                            simulFormEmol[index].nombre = currentEmolSimu[index].nombre
                            simulFormEmol[index].montant = currentEmolSimu[index].montant
                            simulFormEmol[index].montant2 = simulFormEmol[index].nombre * simulFormEmol[index].montant

                            tt += roundDecimal((simulFormEmol[index].montant2), 3)

                        })
                        key.value = roundDecimal(tt, 3).toString() + " €";
                        // calcul a verif
                        arr.map((key) => {
                                if (key["key"] === "Emol_total") {
                                    var emol = parseFloat(simulateurResponse["Emol_total"] - parseFloat(simulateurResponse["emoluments_formalites_total"]) + (tt))
                                    if (majorationEmol125.includes(data.departementid)) {
                                        emol -= parseFloat(simulateurResponse["emoluments_majoration_total"])
                                        emol *= 1.25
                                        arr.map((key) => {
                                                if (key["key"] === "Major") {
                                                    var major = (emol / 1.25) * 0.25
                                                    key["value"] = roundDecimal(major, 2).toString() + " €";
                                                }
                                            }
                                        )
                                    } else if (majorationEmol14.includes(data.departementid)) {
                                        emol -= parseFloat(simulateurResponse["emoluments_majoration_total"])
                                        emol *= 1.4
                                        arr.map((key) => {
                                                if (key["key"] === "Major") {
                                                    var major = (emol / 1.4) * 0.4
                                                    key["value"] = roundDecimal(major, 2).toString() + " €";
                                                }
                                            }
                                        )
                                    }
                                    key["value"] = roundDecimal(emol, 2).toString() + " €";

                                    arr.map((key) => {
                                            if (key["key"] === "TVA_total") {
                                                var tva = emol * parseFloat(simulateurResponse["taux_tva"])
                                                key["value"] = roundDecimal(tva, 2).toString() + " €";
                                                var tresor = parseFloat(simulateurResponse["Tresor_total"])
                                                if (parseInt(data.inputs.acte_en_main) === 1) {
                                                    arr.map((key) => {
                                                        if (key["key"] === "Tresor_total") {
                                                            var baseTresor = data.inputs.montant_vente - emol - tva - parseFloat(simulateurResponse["Debours_total"])
                                                            if (simuVentes.includes(data.simulateurid)) {
                                                                baseTresor -= data.inputs.montant_meuble
                                                                if (alsaceMoselle.includes(data.departementid)) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0580669))
                                                                } else if (data.inputs?.zrr === 1) {
                                                                    if (data.departementid === 51) {
                                                                        if (baseTresor < 7600) {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                        } else {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 350.1
                                                                        }
                                                                    } else if (data.departementid === 71) {
                                                                        if (baseTresor < 30400) {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                        } else {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 1400.42
                                                                        }
                                                                    } else {
                                                                        if (baseTresor < 46000) {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001 + 0.012))
                                                                        } else if (data.departementid === 14) {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 2119.06
                                                                        } else {
                                                                            tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.001)) - 1789.43
                                                                        }
                                                                    }
                                                                } else if (data.inputs.taxe_ile_de_france === 1) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0580669 + 0.001 + 0.006))
                                                                } else if (data.departementid === 976) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0509006 + 0.004))
                                                                } else if (data.departementid === 977) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0492 + 0.001))
                                                                } else if (data.departementid === 978) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.08 + 0.001))
                                                                } else if (mutationReduite.includes(data.departementid)) {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0580665 + 0.001))
                                                                } else {
                                                                    tresor = baseTresor - (baseTresor / (1 + 0.0580669 + 0.001))
                                                                }
                                                            } else {
                                                                var tpf = 0;
                                                                var csiInscription = 0
                                                                var csiPublication = 0
                                                                if (data.departementid !== 977) {
                                                                    if (data.departementid === 978) {
                                                                        tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.01
                                                                    } else if (data.departementid === 973) {
                                                                        tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0035749
                                                                    } else if (hypothequeReduite.includes(data.departementid)) {
                                                                        tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0071422
                                                                    } else {
                                                                        tpf = (baseTresor / (1 + data.inputs.tva_concerne)) * 0.0071498
                                                                    }
                                                                }
                                                                if (!alsaceMoselle.includes(data.departementid)) {
                                                                    if (data.departementid === 976) {
                                                                        csiInscription = baseTresor * 0.004
                                                                        if (csiInscription < 8) {
                                                                            csiInscription = 8
                                                                        }
                                                                        csiPublication = baseTresor * 0.004
                                                                        if (csiPublication < 15) {
                                                                            csiPublication = 15
                                                                        }
                                                                    } else {
                                                                        csiInscription = baseTresor * 0.0005
                                                                        if (csiInscription < 8) {
                                                                            csiInscription = 8
                                                                        }
                                                                        csiPublication = baseTresor * 0.001
                                                                        if (csiPublication < 15) {
                                                                            csiPublication = 15
                                                                        }
                                                                    }
                                                                }
                                                                tresor = tpf + csiInscription + csiPublication
                                                            }
                                                            key["value"] = roundDecimal(tresor, 2).toString() + " €";
                                                        }
                                                    })
                                                }
                                                arr.map((key) => {
                                                        if (key["key"] === "Provision_total") {
                                                            var provision = parseFloat(simulateurResponse["Provision_total"]) - parseFloat(simulateurResponse["Tresor_total"]) + tresor - parseFloat(simulateurResponse["emoluments_formalites_total"]) + parseFloat(tt) - parseFloat(simulateurResponse["TVA_total"]) + tva
                                                            key["value"] = roundDecimal(provision, 2).toString() + " €";
                                                            arr.map((key) => {
                                                                if (key["key"] === "Provision_Pourcentage_total") {
                                                                    if (data.simulateurid === 26) {
                                                                        key.value = roundDecimal(provision / (data.inputs.montant_loyer_annuel * data.inputs.duree_bail) * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_vente) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_vente * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_cautionnement) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_cautionnement * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_acquisition) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_acquisition * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_retrocession) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_retrocession * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_loyer_mensuel) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_loyer_mensuel * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_pret) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_pret * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_creance) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_creance * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_quittance) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_quittance * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_prorogation) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_prorogation * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.masse_brute) {
                                                                        key.value = roundDecimal(provision / data.inputs.masse_brute * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_licitee) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_licitee * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_immeuble) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_immeuble * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_loyer_annuel) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_loyer_annuel * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_loyer) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_loyer * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_loyer_versements) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_loyer_versements * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_loyer) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_loyer * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_donateur_1) {
                                                                        key.value = roundDecimal(provision / (data.inputs.montant_donateur_1 + data.inputs.montant_donateur_2) * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_succession) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_succession * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_nantissement) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_nantissement * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_lot_1) {
                                                                        key.value = roundDecimal(provision / (data.inputs.montant_lot_1 + data.inputs.montant_lot_2) * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_lot) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_lot * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_cession) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_cession * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_investissement) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_investissement * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.valeur_biens_declares) {
                                                                        key.value = roundDecimal(provision / data.inputs.valeur_biens_declares * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.valeur_residuelle) {
                                                                        key.value = roundDecimal(provision / data.inputs.valeur_residuelle * 100, 2).toString() + " %";
                                                                    } else if (data.simulateurid === 80) {
                                                                        key.value = roundDecimal(provision / parseFloat(simulateurResponse["valeur_totale"]) * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_partage) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_partage * 100, 2).toString() + " %";
                                                                    } else if (data.inputs.montant_partage_brut) {
                                                                        key.value = roundDecimal(provision / data.inputs.montant_partage_brut * 100, 2).toString() + " %";
                                                                    } else {
                                                                        key.value = roundDecimal(provision / parseFloat(simulateurResponse["base_emol"]), 2).toString() + " %";
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                )
                                            }
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            )
            localStorage.setItem("outputAffinage", JSON.stringify(arr));
            setCurrentEmolSimu(simulFormEmol)
            //simulateurObject["results"]["output"] = saveSimu
            //setArrayContent(arr)
            simulateurObject["results"]["custom"] = arr
            forceUpdate()
        } else {
            var remainingFormEmol = JSON.parse(localStorage.getItem("contentEmol"));
            var allSortedEmol = currentEmolSimu.concat(remainingFormEmol)
            setCurrentEmolSimu(allSortedEmol)
            forceUpdate()
        }
        forceUpdate()
    }


    function resetEmol() {
        if (simulateurObject["results"]["output"]?.length > 0) {
            setCurrentEmolSimu(JSON.parse(localStorage.getItem("currentEmol")))
            localStorage.removeItem("persoEmol")
            localStorage.removeItem("outputAffinage")
        }
        //setArrayContent(tempArrayContent)
        simulateurObject["results"]["custom"] = []
        forceUpdate()
    }

    let [objValues, setObjValues] = useState({})


    useEffect(() => {
        if (simulateurObject["results"]["custom"].length > 0)
            objValues = simulateurObject["results"]["custom"]
        else
            objValues = simulateurObject["results"]["output"]
    }, [simulateurObject["results"]["output"], simulateurObject["results"]["custom"]]);


    function tablePrint() {
        if (simulateurObject["results"]["custom"]?.length > 0) {
            for (let i = 0; i < simulateurObject["results"]["custom"]?.length; i++) {
                objValues[simulateurObject["results"]["custom"][`${i}`]["key"]] = simulateurObject["results"]["custom"][`${i}`].value.replace('€', '');
            }
        } else
            for (let i = 0; i < simulateurObject["results"]["output"]?.length; i++) {
                objValues[simulateurObject["results"]["output"][`${i}`]["key"]] = simulateurObject["results"]["output"][`${i}`].value.replace('€', '');
            }

        const dataPieChart = {
            labels: ['Emoluments', 'TVA', 'Trésor', 'Débours'], datasets: [{
                data: [Math.round(objValues.Emol_total * 100) / 100, Math.round(objValues.TVA_total * 100) / 100, Math.round(objValues.Tresor_total * 100) / 100, Math.round(objValues.Debours_total * 100) / 100,],
                backgroundColor: ['rgba(182, 169, 153, 0.9)', 'rgba(93,134,141,0.9)', 'rgba(120, 173, 181, 0.9)', 'rgba(226, 164, 143, 0.9)'],
                borderColor: ['rgb(160,148,134)', 'rgb(76,110,116)', 'rgb(101,146,153)', 'rgb(197,143,124)'],
                borderWidth: 1.8,
            },],
        };

        const options: ChartOptions = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return "$" + Number(tooltipItem.yLabel) + " and so worth it !";
                    }
                }
            }, title: {
                display: true, text: 'Ice Cream Truck', position: 'bottom'
            }, animation: {
                onComplete: function () {
                    localStorage.setItem("chart", chartRef?.current?.toBase64Image())
                },
            },

        };

        let elem = null
        if (simulateurObject["results"]["custom"].length > 0)
            elem = simulateurObject["results"]["custom"]
        else elem = simulateurObject["results"]["output"]

        return (<div className={showHideClassName}>
            <div className="modal">
                <div className="modal-main" style={{
                    height: showEmol === true ? "90%" : "80%",
                }}>
                    <>
                        {elem !== undefined ? <div style={styles.ctnTable}>

                                <div style={styles.ctnTableData}>
                                    <div style={{flex: 6}}>
                                        <p style={{
                                            textAlign: "center", fontSize: 20, fontWeight: "bold"
                                        }}>Résultat de votre provision sur frais</p>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row", flexWrap: "wrap",
                                            justifyContent: "space-around"
                                        }}>
                                            <div style={{
                                                width: showEmol === true ? "100%" : "40%",
                                                height: showEmol === true ? "100%" : "40%",
                                            }}>
                                                <table style={{
                                                    ...styles.table, width: showEmol === true ? "100%" : "100%"
                                                }}>

                                                    <tbody>
                                                    {
                                                        elem ? elem.map((value, index) => {
                                                                var currentClass = {backgroundColor: "white"};
                                                                if (value.key === "Provision_total") {
                                                                    currentClass = {backgroundColor: "white"}
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }
                                                                if (value.key === "Provision_Pourcentage_total") {
                                                                    currentClass = {backgroundColor: "white"}
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }
                                                                if (value.key === "Emol_total" || value.key === "Major") {
                                                                    currentClass = {backgroundColor: "rgba(164,148,128,0.8)"}
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }
                                                                if (value.key === "TVA_total" || value.key === "Tresor_total") {
                                                                    currentClass = {backgroundColor: "rgba(71,143,154,0.73)"}
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }
                                                                if (value.key === "Debours_total") {
                                                                    currentClass = {backgroundColor: "rgba(214,127,98,0.71)"}
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }
                                                                if (value.key === "Emol_Form_Total") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(164,148,128,0.8)",
                                                                        borderBottom: "1px solid rgb(238, 236, 236)"
                                                                    }
                                                                    if (value.value === "-1 €") {
                                                                        value.value = "écrêtés"
                                                                    }
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }

                                                                if (value.key === "DMTG") {
                                                                    currentClass = {
                                                                        backgroundColor: "rgba(71,143,154,0.73)",
                                                                        borderBottom: "1px solid rgb(238, 236, 236)"
                                                                    }
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "DMTG" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "DMTG" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }

                                                                if (elem[index + 1] !== undefined) {
                                                                    if (elem[index + 1].key === "Major") {
                                                                        currentClass["borderBottom"] = "";
                                                                    }
                                                                }
                                                                if (value.key === "Major" || value.key === "Tresor_total") {
                                                                    currentClass["borderBottom"] = "1px solid rgb(238, 236, 236)";
                                                                    return (<>
                                                                        <tr style={currentClass}>
                                                                            <td style={{
                                                                                ...styles.td,
                                                                                paddingLeft: value.key === "Major" || value.key === "Emol_Form_Total" ? 25 : 10,
                                                                                fontStyle: value.key === "Major" || value.key === "Emol_Form_Total" ? "italic" : ""
                                                                            }}>{value.name}</td>
                                                                            <td style={styles.td2}>{value.value}</td>
                                                                        </tr>
                                                                    </>)
                                                                }

                                                                if (simulateurObject["results"]["custom"].length > 0)
                                                                    simulateurObject["results"]["custom"] = elem
                                                                else simulateurObject["results"]["output"] = elem
                                                            })

                                                            : <></>}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {showEmol === true ?
                                                <div style={{
                                                    width: "15em",
                                                    height: "15em",
                                                }}>
                                                    <Pie data={dataPieChart} id={"myChart"} ref={chartRef}
                                                         options={options}
                                                    />
                                                </div>

                                                : <div style={{
                                                    //width: 300,
                                                    //height: 300
                                                    width: "20em",
                                                    height: "20em",
                                                }}>
                                                    <Pie data={dataPieChart} id={"myChart"} ref={chartRef}
                                                         options={options}
                                                    />
                                                </div>}
                                        </div>

                                        {buttonPrint()}
                                    </div>
                                    {

                                        showEmol === true ?
                                            <div style={{flex: 10, marginLeft: 15}}>

                                                {/* <p style={{
                                                    textAlign: "center",
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                    color: "white"
                                                }}> test</p> */}
                                                <div style={{overflow: "scroll", height: "50%"}}>
                                                    <table style={styles.table2}>
                                                        <thead style={styles.thead}>
                                                        <tr>
                                                            <th style={styles.th}>Nature des formalités</th>
                                                            <th style={styles.th}>N° du tableau 5</th>
                                                            <th style={styles.th}>Coût de la formalité</th>
                                                            <th style={styles.th}>Nombre de formalité</th>
                                                            <th style={styles.th}>Coût total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody style={{height: "100%", overflow: "scroll",}}>
                                                        {currentEmolSimu !== undefined ? currentEmolSimu.map((value, index) => {
                                                                return (<>
                                                                    <tr style={styles.tr}>
                                                                        <td style={index % 2 === 1 ? styles.td1formTitle : styles.td2formTitle}> {value.formalite}</td>
                                                                        <td style={index % 2 === 1 ? styles.td1formArt : styles.td2formArt}> {value.article}</td>
                                                                        <td style={index % 2 === 1 ? styles.td1form : styles.td2form}> {value.montant}</td>
                                                                        <td style={index % 2 === 1 ? styles.td1form : styles.td2form}>
                                                                            <input style={{width: 20}}
                                                                                   type={"text"}
                                                                                   placeholder={"Montant"}
                                                                                   onChange={(e) => {
                                                                                       setReadyPDF(false)
                                                                                       changeEmol(e.target.value, index)
                                                                                   }}
                                                                                   defaultValue={value.montant2}/>
                                                                        </td>
                                                                        <td style={index % 2 === 1 ? styles.td1form : styles.td2form}> {roundDecimal(value.montant2, 2)}</td>

                                                                    </tr>
                                                                </>)
                                                            })

                                                            : <>
                                                            </>}
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div onClick={event => {
                                                    setReadyPDF(false)
                                                    showAllEmolument()
                                                }} style={{
                                                    marginTop: 20,
                                                    backgroundColor: "rgb(37, 145, 157)",
                                                    color: "white",
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    cursor: "pointer",
                                                    textAlign: "center"
                                                }}>Afficher / Supprimer toutes les formalités
                                                </div>

                                            </div>
                                            :
                                            <></>}


                                    <img src={`${close}`} style={{
                                        position: "absolute",
                                        fontWeight: "bold",
                                        border: "solid 1px",
                                        borderRadius: 5,
                                        borderColor: "black",
                                        right: 20,
                                        height: 20,
                                        cursor: "pointer"
                                    }} onClick={e => {
                                        setCreatePDF(false)
                                        setShowEmol(false)
                                        setReadyPDF(false)
                                        setCalculateDMTGState(false)
                                        resetEmol()
                                        hideModal()
                                    }}/>
                                </div>

                            </div>


                            : <>
                            </>

                        }

                    </>

                </div>
            </div>
        </div>)
    }


    function buttonPrint() {
        //console.log(JSON.stringify(simulateurObject["results"]["output"]))
        //Commented in order to delete PERSONALISATION BUTTONS.
        /*var buttonEmol = false
        simulateurObject["results"]["output"].map(value => {
                //console.log(value.key)
                if (value.key === "Emol_Form_Total") {
                    //console.log("The key is Emol_Form_Total")
                    //console.log("The value is : " + value.value)
                    //console.log("The type of value is : " + typeof value.value)
                    if (value.value !== "écrêtés") {
                        //console.log("The key Emol_Form_Total is not écrêtés")
                        buttonEmol = true
                    }
                }
            }
        )*/
        return (
            <div style={{...styles.ctnButtonValide}}>
                {createPDF === true ?
                    <LinkPDF simulateurObject={simulateurObject}
                             showEmol={showEmol}
                             ready={readyPDF}
                             setReadyMain={setReadyPDF}
                    /> : <></>}
            </div>
        )
    }

    function printCalculateResult() {
        if (parserSimulator().props.children !== "Simulateur non disponible") {
            return (<>
                <div style={styles.ctnButton}>
                    <button
                        style={styles.buttonCalculate}
                        onClick={(e) => {
                            if (listOfSimuWithDMTG.includes(parseInt(props.match.params.simulateur)) && data.inputs["DMTG"] === 1) {
                                //console.log("CALCULATEDMTG")
                                calculateDMTG()
                            } else
                                calculate()
                        }}
                    >
                        Calculer la provision sur frais
                    </button>
                </div>
                {tablePrint()}
            </>)
        } else return <></>
    }

    useEffect(() => {
            if (data?.inputs?.result === 1) {
                calculate()
            }
        },
        [data])

    async function calculateDMTG() {
        setCalculateDMTGState(true)
        //console.log("THISISMYDATA")
        //console.log(JSON.stringify(data))
    }

    function parserSimulator() {
        switch (props.match.params.simulateur) {
            case "1": {
                return (<>
                    <Simulateur_A1_1
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "2": {
                return (<>
                    <Simulateur_A1_2
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "3": {
                return (<>
                    <Simulateur_A1_3
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "4": {
                return (<>
                    <Simulateur_A1_3bis
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "5": {
                return (<>
                    <Simulateur_A2_1
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "6": {
                return (<>
                    <Simulateur_A2_8
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "7": {
                return (<>
                    <Simulateur_A1_4
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "8": {
                return (<>
                    <Simulateur_A1_5
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "9": {
                return (<>
                    <Simulateur_A1_5bis
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "10": {
                return (<>
                    <Simulateur_EDD
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "11": {
                return (<>
                    <Simulateur_MC_EDD
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "12": {
                return (<>
                    <Simulateur_A2_2
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "13": {
                return (<>
                    <Simulateur_A2_3
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "14": {
                return (<>
                    <Simulateur_A2_4
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "15": {
                return (<>
                    <Simulateur_A2_5
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "16": {
                return (<>
                    <Simulateur_A2_6
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "17": {
                return (<>
                    <Simulateur_A2_7
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "18": {
                return (<>
                    <Simulateur_ESD
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "19": {
                return (<>
                    <Simulateur_ESI
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "20": {
                return (<>
                    <Simulateur_ESIM
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "21": {
                return (<>
                    <Simulateur_ESDM
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "22": {
                return (<>
                    <Simulateur_A1_24
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "23": {
                return (<>
                    <Simulateur_A1_25
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "24": {
                return (<>
                    <Simulateur_A1_24bis
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "25": {
                return (<>
                    <Simulateur_A1_25bis
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "26": {
                return (<>
                    <Simulateur_A1_26
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "27": {
                return (<>
                    <Simulateur_A1_27
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "28": {
                return (<>
                    <Simulateur_A1_28
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "29": {
                return (<>
                    <Simulateur_A1_29
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "30": {
                return (<>
                    <Simulateur_A1_30
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "31": {
                return (<>
                    <Simulateur_A1_7
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "32": {
                return (<>
                    <Simulateur_32
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "34": {
                return (<>
                    <Simulateur_34
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "35": {
                return (<>
                    <Simulateur_35
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "36": {
                return (<>
                    <Simulateur_36
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "37": {
                return (<>
                    <Simulateur_37
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "38": {
                return (<>
                    <Simulateur_38
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "39": {
                return (<>
                    <Simulateur_39
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "40": {
                return (<>
                    <Simulateur_40
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "41": {
                return (<>
                    <Simulateur_41
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "43": {
                return (<>
                    <Simulateur_ASimmo1
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "44": {
                return (<>
                    <Simulateur_ASimmo3
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "45": {
                return (<>
                    <Simulateur_ASimmo4
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "46": {
                return (<>
                    <Simulateur_ASimmo5
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "47": {
                return (<>
                    <Simulateur_ASimmo6
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            //Simulateurs de famille supprimés
            case "78": {
                return (<>
                    <Simulateur_78
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "79": {
                return (<>
                    <Simulateur_79
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "80": {
                return (<>
                    <Simulateur_80
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "81": {
                return (<>
                    <Simulateur_81
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            case "82": {
                return (<>
                    <Simulateur_82
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "84": {
                return (<>
                    <Simulateur_A1_12
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "85": {
                return (<>
                    <Simulateur_A1_12VEFA
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "86": {
                return (<>
                    <Simulateur_A1_8
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "87": {
                return (<>
                    <Simulateur_A1_9
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "88": {
                return (<>
                    <Simulateur_A2_14
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "89": {
                return (<>
                    <Simulateur_A2_15
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "90": {
                return (<>
                    <Simulateur_A2_16
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "91": {
                return (<>
                    <Simulateur_A2_17
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "92": {
                return (<>
                    <Simulateur_A1_12PRO
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "93": {
                return (<>
                    <Simulateur_A1_10
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "94": {
                return (<>
                    <Simulateur_A1_11
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "95": {
                return (<>
                    <Simulateur_A2_20
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "96": {
                return (<>
                    <Simulateur_A2_18
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "97": {
                return (<>
                    <Simulateur_A2_19
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "98": {
                return (<>
                    <Simulateur_A1_14
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "99": {
                return (<>
                    <Simulateur_A1_15
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "100": {
                return (<>
                    <Simulateur_A1_16
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            // OK
            case "101": {
                return (<>
                    <Simulateur_A1_16bis
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            //
            case "102": {
                return (<>
                    <Simulateur_A1_17
                        simulateur={parentSimulateur}
                        isParent={true}
                        departements={departements}
                    />
                </>)
            }
            default: {
                return (<h2>Simulateur non disponible</h2>)
            }
        }
    }

    // SWITCH WITH ID SIMULATEUR
    return <div style={styles.container}>
        {parserSimulator()}
        {printCalculateResult()}
    </div>

}

export default SimulateurMain;
